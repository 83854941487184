.p-clickable {
	cursor: pointer;
}

.p-flex-wrap {
	flex-wrap: wrap;
}

.p-img-responsive {
	width: 100%;
	height: auto;
}
.p-img-rounded {
	border-radius: 5px;
	overflow: hidden;
}

.p-galleria-mask {
	// position:
	min-width: 320px;
	// overflow-y: scroll;
	// padding: 5rem 5rem 1rem;
	// @media (max-width: 800px) {
	// 	padding: 5rem 0rem 0rem;
	// }
}
.p-galleria {
	width: 100%;
	max-width: 100%;
}
.p-galleria-close {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	z-index: 1;
}
.p-galleria-content {
	img {
		object-fit: scale-down;
		overflow: auto;
		height: 100%;
	}
}
.p-galleria-item-wrapper {
	height: calc(100vh - 88px);
}
.p-galleria-thumbnail-wrapper {
	height: 88px;
}
.p-galleria-thumbnail-items-container {
	flex: 1;
}
.p-galleria-thumbnail-item-content {
	overflow: hidden;
}

.p-hoverable {
	transition: background-color 0.2s;
	&:hover,
	&:focus {
		background-color: rgba(black, 0.04);
		&.dark {
			background-color: rgba(white, 0.04);
		}
	}
}

.p-hoverable-opacity-img {
	&img,
	img {
		opacity: 1;
		transition: opacity 0.2s;
		&:hover,
		&:focus {
			opacity: 0.85;
		}
	}
}

.p-fieldset .p-fieldset-legend {
	padding: 0.75rem !important;
}

.p-fieldset {
	+ .p-fieldset {
		margin-top: 1rem;
	}
}

.p-message {
	.p-message-detail {
		width: 100%;
		word-break: break-word;
	}
	.p-message-close {
		display: flex !important;
		min-height: 2rem;
		min-width: 2rem;
	}
}

.p-field > .p-field-label {
	display: block;
	margin-bottom: 0.2rem !important;
	font-size: 80%;
}

// .p-dropdown {
// 	.p-dropdown-trigger {
// 		flex-shrink: 1;
// 		width: 1.75rem !important;
// 	}
// }

// .p-button.p-button-icon-only {
// 	width: 1.75rem !important;
// }
// .p-fluid .p-button-icon-only {
// 	width: 1.75rem !important;
// }

.p-inputnumber {
	.p-inputnumber-button-group {
		.p-inputnumber-button {
			width: 1.75rem;
		}

		@media (max-width: 768px) {
			flex-direction: row;

			.p-inputnumber-button-up {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
}

.p-inputgroup > .p-component {
	flex: 1 !important;
}

.p-ripple {
	&.p-ripple-dark {
		.p-ink {
			background: rgba(black, 0.3);
		}
	}
}

.p-tooltip {
	pointer-events: none;
}

.p-steps-responsive > ul {
	flex-wrap: wrap;

	.p-steps-title {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
}

.p-dropdown-header .p-dropdown-filter {
	width: 100% !important;
}

.p-text-info {
	color: #044868;
}

.p-text-warning {
	color: #d88723;
}

.p-text-danger {
	color: #f44336;
}

.p-text-muted {
	color: #696969;
}

.p-inputswitch {
	min-width: 3rem;
}
.p-inputswitch-focus {
	.p-inputswitch-slider {
		box-shadow: 0 0 0 0.2rem #a6d5fa;
	}
}

.p-ink {
	pointer-events: none;
}

.p-field {
	margin-bottom: 0.5rem;
}

.p-sidebar {
	display: flex;
	flex-direction: column;

	.p-sidebar-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;
	}
}

.p-menu {
	&.p-menu-flat {
		width: auto;
		border: none;
	}
}

.p-overlaypanel-content {
	.p-menu {
		margin: 0 -1rem;
	}
}

// my
.p-text-info {
	color: #044868;
}
.p-text-success {
	color: #224a23;
}
.p-text-warn {
	color: #6d5100;
}
.p-text-error {
	color: #73000c;
}

.p-sidebar-top.p-sidebar-sm,
.p-sidebar-bottom.p-sidebar-sm {
	height: 12rem !important;
}

.p-sidebar {
	min-width: 320px;

	.p-sidebar-content {
		overflow: auto;
	}
}

.p-paginator-current {
	cursor: auto !important;
}

.p-hr {
	border: none;
	border-top: 1px solid #dee2e6;
}

.p-multiselect {
	.p-multiselect-label {
		display: flex;
		align-items: center;
	}
	.p-multiselect-token {
		+ .p-multiselect-token {
			margin-left: 0.55rem;
		}
		.p-multiselect-token-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 1.4rem;
			width: 1.4rem;
			margin-left: 0.2rem;
			background-color: transparent;
			transition: background-color 100ms;
			border-radius: 50%;
			&:hover,
			&:focus {
				background-color: #efebeb;
			}
			&:active {
				background-color: #d4d1d1;
			}
		}
	}
}

.p-flex-unset {
	flex: unset !important;
}

.p-inputgroup {
	.p-inputgroup-min {
		width: auto;
		flex: unset !important;
	}
}
.p-disabled,
.p-component:disabled {
	opacity: 0.7;
}

.p-o-h {
	overflow: hidden;
}
