$primaryColorLeft: #3370e0 !default;
$primaryColorRight: #8e24e0 !default;
$primaryColor: #5554e0 !default;

html {
	// overflow-y: scroll;
}

html,
body,
#root {
	min-height: 100vh;
}

body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}

body.noHighlights {
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#root {
	display: flex;
	flex-direction: column;
	color: #13100d;
	overflow-x: hidden;
}

.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

.App-header {
	background-color: #222;
	height: 190px;
	padding: 20px;
	color: white;
}

.App-intro {
	padding: 2em;
	font-size: large;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.Toastify__toast-body {
	max-width: 100%;
	word-break: break-word;
	white-space: normal;
}

.center-wrapper {
	display: flex;
	flex-direction: column;
	flex: 100%;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.fh {
	height: 100%;
}

.big {
	font-size: 115%;
}

.xbig {
	font-size: 125%;
}

.small {
	font-size: 85%;
}

.xsmall {
	font-size: 75%;
}

.app-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.app-wrapper-main {
		display: flex;
		flex-direction: column;
		flex: 100%;
		// overflow-y: scroll;
	}

	.dev-bar {
		color: white;
		display: flex;
		justify-content: space-between;
		background-color: #c44e21;
		align-items: center;

		.dev-bar-menu {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;

			margin-left: 1rem;
			a {
				color: white;
				padding: 0.15rem 1rem;
				display: inline-block;
				border-bottom: 2px solid #733016;

				&.active {
					background-color: rgba(black, 0.2);
					border-color: white;
					&:hover {
						background-color: rgba(black, 0.1);
					}
				}

				&:not(.active):hover {
					background-color: rgba(white, 0.1);
				}

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}
	}
}

.p-fixed-100 {
	.p-field {
		label {
			width: 100px;
		}
		.form-static {
			display: inline-block;
		}
	}
}

.details {
	.field {
		margin-top: 0.5rem;
		.label {
			display: block;
			font-size: 80%;
		}
		.value {
			display: block;
			word-break: break-word;
			padding-right: 0.5rem;
		}
	}
}

.strong {
	font-weight: bold;
}

body,
.p-dialog-mask {
	min-width: 320px;
	.p-dialog-mask {
		padding: 0.5rem;
	}
}

a:not(.p-button) {
	color: $primaryColor;
	&:hover,
	&:focus {
		color: $primaryColor;
	}
	&:visited {
		color: darken($primaryColor, 0.1);
		&:hover,
		&:focus {
			color: darken($primaryColor, 0.1);
		}
	}
}

.scrollable {
	overflow: scroll;
}

.onmobile-full {
	@media (max-width: 600px) {
		width: 100%;
		display: block;
	}
}

.h-100 {
	height: 100%;
}

.w100 {
	width: 100%;
}

$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$grid-gutter-width: 30px !default;
$container-tablet: (720px + $grid-gutter-width) !default;
$container-desktop: (940px + $grid-gutter-width) !default;
$container-large-desktop: (1140px + $grid-gutter-width) !default;

// Centered container element
@mixin container-fixed($gutter: $grid-gutter-width) {
	padding-right: ceil(($gutter / 2));
	padding-left: floor(($gutter / 2));
	margin-right: auto;
	margin-left: auto;
	//@include clearfix;
}

.container {
	@include container-fixed;

	@media (min-width: $screen-sm-min) {
		width: $container-tablet;
	}
	@media (min-width: $screen-md-min) {
		width: $container-desktop;
	}
	@media (min-width: $screen-lg-min) {
		width: $container-desktop;
	}

	&.container-mini {
		width: calc($container-desktop/2 + 2rem);
		@media (max-width: $screen-sm-min) {
			width: 100%;
		}
	}
}

.flex-full {
	flex: 1;
}

.fm {
	position: fixed;
	z-index: 1000;
	display: none;

	&.open {
		display: block;
	}

	&.fm-rb {
		bottom: 1rem;
		right: 1rem;
	}
	&.fm-rt {
		top: 1rem;
		right: 1rem;
	}
	&.fm-lb {
		left: 1rem;
		bottom: 1rem;
	}
	&.fm-lt {
		left: 1rem;
		top: 1rem;
	}

	.fm-content {
		display: flex;
		flex-direction: column;
	}
}

.notification-panel {
	max-width: 250px;
}
.notificaiton-menu {
	.notification-menuitem {
		position: relative;

		.notification-menuitem-content {
			overflow: hidden;
			white-space: nowrap;
			> .title,
			> .subtitle {
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		.notification-menuitem-date {
			position: absolute;
			right: 1rem;
			top: 0.05rem;
			font-size: 0.75rem;
		}

		.notification-menuitem-link {
			padding: 0.5rem 1rem 0.25rem;
			&.unreaded {
				.notification-menuitem-title {
					font-weight: bold;
				}
			}
		}
	}
}

.card-page {
	width: 100%;
	max-width: 1400px;
	// overflow: hidden; // drodowny uciekawjo
}

.p-success {
	color: #689f38;
}

/* Hide all default focus states if a mouse is used, this is completely optional ofcourse */
*:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: none;
}

/* Default outline value, which will be applied to all elements receiving focus, this is a required step. */
/* The .focus class is used by the focus target, more below. */
.floating-focus-enabled :focus,
.floating-focus-enabled .focus {
	outline: dodgerblue solid 2px !important;
	outline-offset: 4px !important;
}
